import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';

const PatientAccess = () => {
  return (
    <Layout>
      <Seo
        title="Patient Access and API for Cures Act Resources | Bridge"
        description="Learn about the Cures Act and how patient access and APIs are required for compliance."
        canonical="/patient-access/"
      />
      <Section variant="hero">
        <Container>
          <Row>
            <Col>
              <h1>Patient Access</h1>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <h2>What&apos;s an API?</h2>
              <p>
                An Application Programming Interface (API) is a computing
                interface to a software component or a system, that defines how
                other components or systems can use it¹. It defines the kinds of
                calls or requests that can be made, how to make them, the data
                formats that should be used, the conventions to follow, etc. It
                can also provide extension mechanisms so that users can extend
                existing functionality in various ways and to varying degrees.
              </p>
              <h2>What is FHIR?</h2>
              <p>
                According to the HL7 organization, the creators of FHIR… FHIR® –
                Fast Healthcare Interoperability Resources (hl7.org/fhir) – is a
                next-generation standard&apos;s framework created by HL7². FHIR
                combines the best features of HL7&apos;s v2 , HL7 v3, and CDA
                product lines while leveraging the latest web standards and
                applying a tight focus on implementability. FHIR solutions are
                built from a set of modular components called
                &apos;Resources&apos;. These resources can easily be assembled
                into working systems that solve real-world clinical and
                administrative problems at a fraction of the price of existing
                alternatives. FHIR is suitable for use in a wide variety of
                contexts – mobile phone apps, cloud communications, EHR-based
                data sharing, server communication in large institutional
                healthcare providers, and much more.
              </p>
              <h2>Why FHIR?</h2>
              <p>
                CMS, in partnership with the Office of the National Coordinator
                for Health Information Technology (ONC), has identified Health
                Level 7® (HL7) Fast Healthcare Interoperability Resources®
                (FHIR) Release 4.0.1 as the foundational standard³ to support
                data exchange via secure application programming interfaces
                (APIs). CMS is adopting the standards for FHIR-based APIs being
                finalized by HHS in the ONC 21st Century Cures Act rule at 45
                CFR 170.215. These requirements support the privacy and security
                of patient information.
              </p>
              <h2>
                What are the requirements to meet the “Provide Patient
                Electronic Access to Their Health Information” measure?
              </h2>
              <p>
                This final rule is focused on driving interoperability³ and
                patient access to health information by liberating patient data
                using CMS authority to regulate Medicare Advantage (MA),
                Medicaid, CHIP, and Qualified Health Plan (QHP) issuers on the
                Federally-facilitated Exchanges (FFEs).
              </p>
              <h3>1) Providing patient access software</h3>
              <p>
                The patient (or the patient-authorized representative) is
                provided timely access to view online, download, and transmit
                his or her health information. Access to the health information
                must be provided by both a portal and via an API.
              </p>
              <h3>
                2) Enabling the API functionality for use with an app of the
                patient&apos;s choice.
              </h3>
              <p>
                API functionality must be fully enabled such that any patient
                access software application chosen by a patient would allow them
                to gain access to their individual health information, provided
                the application is configured to meet the technical
                specifications of the API. It is not the responsibility of the
                eligible professionals to provide the application (Final Rule
                pg. 520).
              </p>
              <p>
                It is their responsibility to meet the functional standard for
                on-boarding an application. As such, security policies and
                procedures need to be in place to screen and vet apps that have
                been brought forward by the patient (see “Providing API and
                Smartphone Access” for more information). Should zero patients
                request to connect via an application during a given report
                period, there would be no penalty applied for low buy-in with
                API usage.
              </p>
              <h2>Providing API and Smartphone Access to Patients</h2>
              <p>
                From Bridge&apos;s perspective, the regulations set forth in the
                21st Century Cures Act that make rule changes to the CMS and ONC
                legislation falls into a “gray area”. It is Bridge&apos;s
                interpretation that a ONC-certified EHR / EHR modular solution
                is required to provide FHIR-ready API access to the healthcare
                organization. The healthcare organization should make it easy
                for patients to access their patient information via a portal
                and their own or recommended smartphone app. This would
                typically be an app provided by the certified EHR vendor or one
                provided by the healthcare organization using the EHR
                vendor-provided API. There is a clear responsibility that is put
                on the EHR/Portal vendor and/or mobile app developer to follow
                the guidelines set forth in the final rule, as well as other
                regulations set by the FDC and HIPAA for example.{' '}
                <strong>
                  In any case, API and smartphone access supporting the USCDI
                  data set should be provided to patients by the healthcare
                  organization in collaboration with health IT vendors.
                </strong>
              </p>
              <p>
                It is Bridge&apos;s advice to their clients that the
                “conversation” around which apps can be used by patients to
                access their health information should be controlled as much as
                possible by the healthcare organization. Providing patient
                access software such as a portal with limited functionality and
                especially one that is not mobile-responsive will drive a high
                number of requests for an app and/or API access. If no
                healthcare organization-sponsored smartphone app or list of
                compatible 3rd party smartphone apps is provided to the patient,
                and only the API access offered, the healthcare organization
                runs a significant risk of needing to respond to the many and
                diverse demands of patients to use the app of their personal
                preference.{' '}
                <strong>
                  The goal is simply to control the conversation and give
                  patients what they will likely want in a portal and smartphone
                  app offering.
                </strong>
              </p>
              <h2>Bridge Patient Portal&apos;s FHIR-Ready API</h2>
              <p>
                Bridge Patient Portal is an ONC Certified 2015 Edition EHR
                Modular solution. View Bridge&apos;s CHPL{' '}
                <a
                  href="https://chpl.healthit.gov/#/organizations/developers/948"
                  target="_blank"
                  rel="noreferrer"
                >
                  here.
                </a>
              </p>
              <p>
                In healthcare, APIs help connect different systems together. In
                the case of Bridge Patient Portal, this is typically Bridge
                Patient Portal connecting to a healthcare organization&apos;s
                Electronic Health Record (EHR) or Revenue Cycle Management (RCM)
                software. Bridge&apos;s API can also be used to connect
                websites, apps, etc. to Bridge Patient Portal. Bridge is an EHR
                vendor-neutral patient engagement software vendor. Bridge&apos;s
                API can be used for both robust integrations with EHR/PM/RCM
                software systems, as well as patient access software, in the
                event that their EMR/PM/RCM vendor is unable to meet the FHIR
                API requirements for patient access.
              </p>

              <hr />
              <p>
                Please contact Bridge Patient Portal&apos;s team with any
                questions.
              </p>
              <ol className="h-reference-list">
                <li>
                  Wikipedia. (2020). API. [online] Available at:
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://en.wikipedia.org/wiki/API"
                  >
                    https://en.wikipedia.org/wiki/API
                  </a>
                </li>
                <li>
                  www.hl7.org. (n.d.). Modules - FHIR v4.0.1. [online] Available
                  at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.hl7.org/fhir/modules.html"
                  >
                    https://www.hl7.org/fhir/modules.html
                  </a>
                </li>
                <li>
                  www.cms.gov. (n.d.). Interoperability and Patient Access Fact
                  Sheet | CMS. [online] Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cms.gov/newsroom/fact-sheets/interoperability-and-patient-access-fact-sheet"
                  >
                    https://www.cms.gov/newsroom/fact-sheets/interoperability-and-patient-access-fact-sheet
                  </a>
                </li>
              </ol>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default PatientAccess;
